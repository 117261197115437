import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import '../styles/patientsPage.css';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const CACHE_DURATION = 60 * 60 * 1000; // 1 hour in milliseconds

const fetchPatientDataByPatientId = async (patientId) => {
    const requestUrl = `https://tracktb.com/api/responses/patient/${patientId}`;
    console.log(`Fetching data for patient ${patientId}`);

    const cachedResponses = localStorage.getItem(`responses_${patientId}`);
    if (cachedResponses) {
        const parsedCachedResponses = JSON.parse(cachedResponses);
        if (parsedCachedResponses.timestamp && Date.now() - parsedCachedResponses.timestamp < CACHE_DURATION) {
            console.log(`Using cached responses for patient ID ${patientId}.`);
            return parsedCachedResponses.data;
        }
    }

    try {
        const token = localStorage.getItem('jwtToken');
        if (!token) {
            throw new Error('JWT token not found in localStorage');
        }

        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        };
        
        const response = await fetch(requestUrl, {
            method: 'GET',
            headers: headers,
            credentials: 'include',
        });

        if (!response.ok) {
            console.error(`Network response was not ok for patient ID: ${patientId}`, response);
            throw new Error(`Network response was not ok for patient ID: ${patientId}`);
        }

        const data = await response.json();
        localStorage.setItem(`responses_${patientId}`, JSON.stringify({ timestamp: Date.now(), data }));
        return data;
    } catch (error) {
        console.error(`Error fetching data for patient ${patientId}:`, error);
        return [];
    }
};

const calculateAdherenceRate = (responses) => {
    const question1Responses = responses.filter(r => r.questionID === 1);
    const totalQuestion1Responses = question1Responses.length;
    const full = question1Responses.filter(r => r.responseValue === 3).length;
    const adherenceRate = totalQuestion1Responses ? (full / totalQuestion1Responses) * 100 : 0;
    console.log(`Calculated adherence rate and loading information.`);
    return adherenceRate.toFixed(2);
};

const PatientsPage = () => {
    const navigate = useNavigate();
    const [displayedPatients, setDisplayedPatients] = useState([]);
    const [allPatients, setAllPatients] = useState([]);
    const [searchFilter, setSearchFilter] = useState('');
    const [isPrimaryProvider, setIsPrimaryProvider] = useState(false);

    const handleFilterChange = (e) => {
        setSearchFilter(e.target.value);
    };

    const handleSearch = () => {
        const filteredPatients = allPatients.filter((patient) =>
            (patient.patientId ?? '').toString().includes(searchFilter)
        );
        setDisplayedPatients(filteredPatients);
    };

    const handleInviteClick = () => {
        navigate('/invite-member');
    };

    useEffect(() => {
        const fetchDoctorAndPatients = async () => {
            const userId = localStorage.getItem('userId');
            const userRole = localStorage.getItem('userRole');
            
            try {
                const doctorResponse = await fetch(`https://tracktb.com/doctors/user/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
                    },
                });

                if (!doctorResponse.ok) {
                    throw new Error('Failed to fetch doctor information');
                }

                const doctorData = await doctorResponse.json();
                const doctorId = doctorData.doctorId;

                const patientsResponse = await fetch('https://tracktb.com/patients', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
                    },
                });

                if (!patientsResponse.ok) {
                    throw new Error('Failed to fetch patients');
                }

                let patientsData = await patientsResponse.json();

                if (userRole === 'Primary Provider' && doctorId) {
                    setIsPrimaryProvider(true);
                    const doctorIdStr = doctorId.toString();
                    patientsData = patientsData.filter(patient =>
                        patient.assignedProvider === doctorIdStr
                    );
                } else {
                    console.log('Setting isPrimaryProvider to false');
                }

                const updatedPatientsData = await Promise.all(
                    patientsData.map(async (patient) => {
                        const responses = await fetchPatientDataByPatientId(patient.patientId);
                        if (responses.length === 0) {
                            console.warn(`No responses found for patient ID: ${patient.patientId}`);
                        }
                        const adherenceRate = calculateAdherenceRate(responses);
                        return { ...patient, adherenceRate };
                    })
                );

                setAllPatients(updatedPatientsData);
                setDisplayedPatients(updatedPatientsData);
            } catch (error) {
                console.error(error);
            }
        };

        fetchDoctorAndPatients();
    }, []);

    const userRole = localStorage.getItem('userRole');

    return (
        <div className="patient-list-container">
            <div className="patient-list-header">
                <h2>Patient List</h2>
                {isPrimaryProvider && (
                    <div>
                        <button className="invite-button" onClick={handleInviteClick}>
                            Invite New Patient
                        </button>
                    </div>
                )}
            </div>
            <div>
                <input 
                    className="search-patient" 
                    placeholder="Enter patient ID" 
                    value={searchFilter}
                    onChange={handleFilterChange} 
                />
                <button className="search-button" onClick={handleSearch}>Search</button>
            </div>
            
            <table className="patient-list-table">
                <thead>
                    <tr>
                        <th>TB ID</th>
                        <th>Phone Number</th>
                        <th>Adherence Rate</th>
                        {!isPrimaryProvider && <th>Assigned Provider</th>}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {displayedPatients.map((patient) => (
                        <tr key={patient.patientId}>
                            <td>{isPrimaryProvider || userRole === 'Patient' ? patient.patientId : '*******'}</td>
                            <td>{isPrimaryProvider || userRole === 'Patient' ? patient.phoneNumber : '***-***-*****'}</td>
                            <td>
                                <div className="progress-bar-background" title={`${Math.floor(patient.adherenceRate)}%`}>
                                    <div
                                        className="progress-bar-foreground"
                                        style={{ width: `${patient.adherenceRate}%` }}
                                    >
                                    </div>
                                </div>
                            </td>
                            {!isPrimaryProvider && <td>{patient.assignedProvider}</td>}
                            <td>
                                <NavLink
                                    style={{ textDecoration: 'none' }}
                                    to={`/patient/${patient.patientId}`}
                                    className={({ isActive }) => isActive ? 'active' : undefined}
                                >
                                    <ArrowForwardIcon className="arrowIcon" />
                                </NavLink>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default PatientsPage;
