import React, { useEffect, useState } from 'react';
import { styled } from "@mui/material/styles";
import { Box, Paper } from "@mui/material";
import "../styles/homePage.css";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  borderRadius: theme.spacing(3),
  margin: "10px",
  height: "50px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
}));

const Heading = styled("h3")({
  marginLeft: "10px",
});

const Stat = styled("h3")({
  marginRightx: "10px",
  color: "rgb(113,146,224)",
});

const CACHE_KEY = 'overviewStats';
const CACHE_DURATION = 60 * 60 * 1000; // 1 hour in milliseconds

const OverviewStats = ({  }) => {
  const [totalLogs, setTotalLogs] = useState(null);
  const [totalPatients, setTotalPatients] = useState(null);
  const [fullAdherence, setFullAdherence] = useState(null);
  const [partialAdherence, setPartialAdherence] = useState(null);

  const fetchData = async () => {

    try {
      const cachedData = localStorage.getItem(CACHE_KEY);
      const parsedData = cachedData ? JSON.parse(cachedData) : null;

      if (parsedData && (Date.now() - parsedData.timestamp < CACHE_DURATION)) {
        setTotalLogs(parsedData.data.totalLogs);
        setTotalPatients(parsedData.data.totalPatients);
        setFullAdherence(parseFloat(parsedData.data.fullAdherence).toFixed(2));
        setPartialAdherence(parseFloat(parsedData.data.partialAdherence).toFixed(2));
      } else {
        const response = await fetch('https://tracktb.com/api/responses/overview-stats', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const stats = await response.json();

        setTotalLogs(stats.totalLogs);
        setTotalPatients(stats.totalPatients);
        setFullAdherence(stats.fullAdherence);
        setPartialAdherence(stats.partialAdherence);

        localStorage.setItem(CACHE_KEY, JSON.stringify({
          data: stats,
          timestamp: Date.now(),
        }));
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box className="overview-stats">
      <Item>
        <Heading>Total Logs</Heading>
        <Stat>{totalLogs}</Stat>
      </Item>
      <Item>
        <Heading>Total Patients</Heading>
        <Stat>{totalPatients}</Stat>
      </Item>
      <Item>
        <Heading>Full Adherence</Heading>
        <Stat>{typeof fullAdherence !== 'null' ? Number(fullAdherence).toFixed(2) : 'N/A'}%</Stat>
      </Item>
      <Item>
        <Heading>Partial Adherence</Heading>
        <Stat>{typeof partialAdherence !== 'null' ? Number(partialAdherence).toFixed(2) : 'N/A'}%</Stat>
      </Item>
    </Box>
  );
};

export default OverviewStats;
