import React from 'react';
import { ReactComponent as DashboardIcon } from '../styles/icons/home-1-svgrepo-com.svg';
import { ReactComponent as ProviderIcon } from '../styles/icons/medical-kit-svgrepo-com.svg';
import { ReactComponent as ProgIcon } from '../styles/icons/administrator-developer-icon.svg';

import { ReactComponent as PatientsIcon } from '../styles/icons/users-group-rounded-svgrepo-com.svg';
import { ReactComponent as ReportingIcon } from '../styles/icons/chart-svgrepo-com.svg';
import { ReactComponent as InviteIcon } from '../styles/icons/user-plus-rounded-svgrepo-com.svg';
import { ReactComponent as LogOutIcon } from '../styles/icons/login-svgrepo-com.svg';
import SidebarImage from '../styles/images/SideBarImage.png'; 
import { NavLink, useNavigate } from 'react-router-dom';

import '../styles/sideBar.css';

const Sidebar = () => {
    const navigate = useNavigate();
    const userRole = localStorage.getItem('userRole');
    const jwtToken = localStorage.getItem('jwtToken');
//Fix this to fully go back to log in page. 
    const handleLogout = () => {
    const confirmLogout = window.confirm('Are you sure you want to log out?');
    if (confirmLogout) {
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('userRole');

        navigate('/login', { replace: true });
        window.location.reload();

    }
    };
    return (
    <div className="sidebar">
        <div className="sidebar-header">
        <img src={SidebarImage} alt="Sidebar" className="sidebar-image" /> 
        </div>
        <div class="divider-line"></div>
        <ul className="sidebar-menu">
            <li>
                <NavLink to="/dashboard" className={({ isActive }) => isActive ? 'active' : undefined}>
                <DashboardIcon className="sidebar-icon" />
                Dashboard
                </NavLink>
            </li>
            <li>
            {userRole !== 'Primary Provider' && (
                <NavLink to="/providers" className={({ isActive }) => isActive ? 'active' : undefined}>                
                <ProviderIcon className="sidebar-icon" />
                Providers</NavLink>
            )}
            </li>
            <li>
                {(userRole === 'Primary Provider' || userRole === 'Secondary Provider') && (
                    <NavLink to="/patients" className={({ isActive }) => isActive ? 'active' : undefined}>
                        <PatientsIcon className="sidebar-icon" />
                        Patients
                    </NavLink>
                )}
            </li>
            <li>
            {userRole === 'Super Admin' && (
                <NavLink to="/prog-admin" className={({ isActive }) => isActive ? 'active' : undefined}>                
                <ProgIcon className="sidebar-icon" />
                Program Administrators</NavLink>
            )}
            </li>
            
            <li>
                <NavLink to="/invite-member" activeClassName="active">
                <InviteIcon className="sidebar-icon" />
                {userRole === 'Primary Provider' ? 'Invite Patient' : 'Invite Provider'}
                </NavLink>
            </li>
            <div class="divider-line"></div>
            <li onClick={handleLogout}>
                <NavLink to="/logout" activeClassName="active" className="logout">
                <LogOutIcon className="sidebar-icon" />
                Logout</NavLink>
            </li>
        </ul>
    </div>
    );
};

export default Sidebar;