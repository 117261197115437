import React, { useState, useEffect } from 'react';
import ExportData from './exportData.js';

const ADR_OPTIONS = [
    { label: 'Weakness', questionID: 4 },
    { label: 'Rash, itching or jaundice', questionID: 5 },
    { label: 'Abdominal pains, nausea, vomiting or diarrhea', questionID: 6 },
    { label: 'Chest pains or palpitations', questionID: 7 },
    { label: 'Joint pains', questionID: 8 },
    { label: 'Difficulty urinating', questionID: 9 },
    { label: 'Depression, anxiety, seizures or numbness', questionID: 10 },
    { label: 'Blurred vision', questionID: 11 },
    { label: 'Hearing difficulties', questionID: 12 },
    { label: 'Pallor', questionID: 13 }
];

const SEVERITY_LABELS = {
    1: 'None',
    2: 'Mild',
    3: 'Moderate',
    4: 'Severe'
};

const fetchResponsesByPatientId = async (patientId) => {
    const cachedResponses = localStorage.getItem(`responses_${patientId}`);
    if (cachedResponses) {
        return JSON.parse(cachedResponses).data || [];
    }

    try {
        const response = await fetch(`https://tracktb.com/api/responses/patient/${patientId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        localStorage.setItem(`responses_${patientId}`, JSON.stringify(data));
        return data.data || [];
    } catch (error) {
        console.error('Error fetching data:', error);
        return [];
    }
};

const processLogs = (responses) => {
    if (!Array.isArray(responses)) {
        console.error('Expected responses to be an array, but got:', responses);
        return [];
    }

    const logs = [];

    // Group responses by date
    const groupedResponses = responses.reduce((acc, response) => {
        const date = new Date(response.timestamp).toLocaleDateString();
        if (!acc[date]) acc[date] = [];
        acc[date].push(response);
        return acc;
    }, {});

    // Get the list of all unique dates with responses
    const responseDates = Object.keys(groupedResponses);

    // Determine the start and end date from the responses
    const startDate = new Date(Math.min(...responses.map(r => new Date(r.timestamp))));
    const endDate = new Date(Math.max(...responses.map(r => new Date(r.timestamp))));

    // Iterate over all dates in the range from startDate to endDate
    for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
        const currentDate = d.toLocaleDateString();
        const dayResponses = groupedResponses[currentDate];

        if (dayResponses) {
            // Process the responses for this date
            const question1Response = dayResponses.find(r => r.questionID === 1);

            if (question1Response) {
                let adherence = 'None';
                if (question1Response.responseValue === 2) adherence = 'Some';
                if (question1Response.responseValue === 3) adherence = 'All';

                const adrs = dayResponses
                    .filter(r => ADR_OPTIONS.some(option => option.questionID === r.questionID && r.responseValue > 1))
                    .reduce((acc, r) => {
                        const option = ADR_OPTIONS.find(option => option.questionID === r.questionID);
                        const adrLabel = `${SEVERITY_LABELS[r.responseValue]} ${option.label}`;
                        if (!acc.includes(adrLabel)) acc.push(adrLabel);
                        return acc;
                    }, []);

                logs.push({
                    date: currentDate,
                    adherence,
                    adrs: adrs.length ? adrs.join(', ') : 'No ADRs experienced for the given day'
                });
            }
        } else {
            // No responses for this date, add a "No Response" log
            logs.push({
                date: currentDate,
                adherence: 'No Response',
                adrs: 'No ADRs reported'
            });
        }
    }

    return logs;
};

const PatientLogs = ({ patientId }) => {
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        const loadData = async () => {
            const responses = await fetchResponsesByPatientId(patientId);
            const processedLogs = processLogs(responses);
            setLogs(processedLogs);
        };

        loadData();
    }, [patientId]);

    return (
        <div className="patient-logs-container">
            <h2>Patient Logs</h2>
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Adherence</th>
                        <th>ADRs</th>
                    </tr>
                </thead>
                <tbody>
                    {logs.map((log, index) => (
                        <tr key={index} className={index % 2 === 0 ? 'even-row' : ''}>
                            <td>{log.date}</td>
                            <td>{log.adherence}</td>
                            <td>{log.adrs}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="button-container">
                <ExportData patientId={patientId} />
            </div>
        </div>
    );
};

export default PatientLogs;
