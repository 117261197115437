import React, { useState, useEffect } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

const CACHE_DURATION = 60 * 60 * 1000; // 1 hour in milliseconds
const CACHE_KEY = 'adherenceResponses';

const AdherenceChart = ({ dateRange}) => {
  const [adherenceData, setAdherenceData] = useState([]);

  const fetchData = async () => {

    const startDate = dateRange[0].toISOString();
    const endDate = dateRange[1].toISOString();

    try {
      // Check if data is already cached in local storage
      const cachedResponses = localStorage.getItem(CACHE_KEY);
      console.log("CACHED RESPONSES: ", cachedResponses);
      if (cachedResponses) {
        const { data, timestamp } = JSON.parse(cachedResponses);
        if (Date.now() - timestamp < CACHE_DURATION) {
          const filteredResponses = data.filter(response =>
            new Date(response.date) >= new Date(startDate) && new Date(response.date) <= new Date(endDate)
          );

          setAdherenceData(filteredResponses);
          return;
        } else {
          // Clear the expired cache
          localStorage.removeItem(CACHE_KEY);
        }
      }

      // If not cached or cache expired, fetch data from the API
      const response = await fetch(`https://tracktb.com/api/responses/date?start=${startDate}&end=${endDate}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responses = await response.json();

      // Cache the fetched data in local storage
      localStorage.setItem(CACHE_KEY, JSON.stringify({ data: responses, timestamp: Date.now() }));

      setAdherenceData(responses);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dateRange]);

  return (
    <div className='adherence-chart-container'>
      <style>
        {`
          .custom-tooltip {
            background: rgba(255, 255, 255, 0.8);
            border: 1px solid #ccc;
            padding: 10px;
            font-size: 12px;
          }

          .custom-tooltip p {
            margin: 0;
            padding: 2px 0;
          }
        `}
      </style>
      <ResponsiveContainer width="100%" height={200} className="adherence-chart">
        <BarChart
          data={adherenceData}
          margin={{
            top: 20, right: 30, left: 20, bottom: 50,
          }}
        >
          <XAxis dataKey="date" interval={15} />
          <YAxis domain={[0, 100]} ticks={[25, 50, 75, 100]} tickFormatter={(value) => `${value}%`} />
          <Tooltip formatter={(value, name, props) => {
            const { payload } = props;
            const noResponse = payload ? payload.noResponse : 0;

            if (typeof value === 'number') {
              if (name === 'full' || name === 'some' || name === 'none') {
                return [`${value.toFixed(2)}%`, name];
              }
              return [value, name]; // No percentage sign for NoResponse
            }
            return [value, name];
          }} 
          content={({ payload, label }) => {
            if (payload && payload.length) {
              const data = payload[0].payload;
              return (
                <div className="custom-tooltip">
                  <p className="label">{`${label}`}</p>
                  <p>{`Full: ${data.full.toFixed(2)}%`}</p>
                  <p>{`Some: ${data.some.toFixed(2)}%`}</p>
                  <p>{`None: ${data.none.toFixed(2)}%`}</p>
                  <p>{`No Response: ${data.noResponse} patients`}</p>
                </div>
              );
            }
            return null;
          }} />
          <Legend wrapperStyle={{ top: -35, left: 0 }} />
          <Bar dataKey="full" stackId="a" fill="#82ca9d" />
          <Bar dataKey="some" stackId="a" fill="#ffc658" />
          <Bar dataKey="none" stackId="a" fill="#ff6361" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AdherenceChart;
