import React, { useState, useEffect } from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import SidebarImage from '../styles/images/SideBarImage.png';

const ADR_OPTIONS = [
    { label: 'Weakness', questionID: 4 },
    { label: 'Rash, itching or jaundice', questionID: 5 },
    { label: 'Abdominal pains, nausea, vomiting or diarrhea', questionID: 6 },
    { label: 'Chest pains or palpitations', questionID: 7 },
    { label: 'Joint pains', questionID: 8 },
    { label: 'Difficulty urinating', questionID: 9 },
    { label: 'Depression, anxiety, seizures or numbness', questionID: 10 },
    { label: 'Blurred vision', questionID: 11 },
    { label: 'Hearing difficulties', questionID: 12 },
    { label: 'Pallor', questionID: 13 }
];

const SEVERITY_LABELS = {
    1: 'None',
    2: 'Mild',
    3: 'Moderate',
    4: 'Severe'
};

const fetchPatientDataByPatientId = async (patientId) => {
    const cachedResponses = localStorage.getItem(`responses_${patientId}`);
    if (cachedResponses) {
        return JSON.parse(cachedResponses).data || [];
    }

    try {
        const response = await fetch(`https://tracktb.com/api/responses/patient/${patientId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        localStorage.setItem(`responses_${patientId}`, JSON.stringify(data));
        return data.data || [];
    } catch (error) {
        console.error(`Error fetching data for patient ${patientId}:`, error);
        return [];
    }
};

const processLogs = (responses) => {
    if (!Array.isArray(responses)) {
        console.error('Expected responses to be an array, but got:', responses);
        return [];
    }

    const logs = [];

    const groupedResponses = responses.reduce((acc, response) => {
        const date = new Date(response.timestamp).toLocaleDateString();
        if (!acc[date]) acc[date] = [];
        acc[date].push(response);
        return acc;
    }, {});

    for (const date in groupedResponses) {
        const dayResponses = groupedResponses[date];
        const question1Response = dayResponses.find(r => r.questionID === 1);

        if (question1Response) {
            let adherence = 'None';
            if (question1Response.responseValue === 2) adherence = 'Some';
            if (question1Response.responseValue === 3) adherence = 'All';

            const adrs = dayResponses
                .filter(r => ADR_OPTIONS.some(option => option.questionID === r.questionID && r.responseValue > 1))
                .reduce((acc, r) => {
                    const option = ADR_OPTIONS.find(option => option.questionID === r.questionID);
                    const adrLabel = `${SEVERITY_LABELS[r.responseValue]} ${option.label}`;
                    if (!acc.includes(adrLabel)) acc.push(adrLabel);
                    return acc;
                }, []);

            logs.push({ date, adherence, adrs: adrs.join(', ') });
        }
    }

    return logs;
};

const ExportData = ({ patientId }) => {
    const [patientOverview, setPatientOverview] = useState(null);
    const [patientLogs, setPatientLogs] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const responses = await fetchPatientDataByPatientId(patientId);

            if (!Array.isArray(responses)) {
                console.error('Expected responses to be an array, but got:', responses);
                return;
            }

            // Process overview data
            const phoneNumber = responses[0]?.phoneNumber || '******';
            const question1Responses = responses.filter(r => r.questionID === 1);
            const totalQuestion1Responses = question1Responses.length;
            const full = question1Responses.filter(r => r.responseValue === 3).length;
            const partial = question1Responses.filter(r => r.responseValue === 2).length;
            const none = question1Responses.filter(r => r.responseValue === 1).length;
            const adherenceRate = totalQuestion1Responses ? (full / totalQuestion1Responses) * 100 : 0;

            const missedDoses = responses.filter(r => r.questionID === 1 && r.responseValue === 0).length;
            const severeAdrs = responses
                .filter(r => ADR_OPTIONS.some(option => option.questionID === r.questionID && r.responseValue === 4))
                .map(r => `${SEVERITY_LABELS[r.responseValue]} ${ADR_OPTIONS.find(option => option.questionID === r.questionID).label}`);

            const overviewData = {
                phoneNumber: phoneNumber,
                fullDoses: full,
                partialDoses: partial,
                missedDoses: none,
                adherenceRate: adherenceRate.toFixed(2),
                severeAdrs: Array.from(new Set(severeAdrs)) // Remove duplicates
            };

            setPatientOverview(overviewData);

            // Process logs data
            const logs = processLogs(responses);
            setPatientLogs(logs);

            setLoading(false);
        };

        fetchData();
    }, [patientId]);

    const exportPDF = () => {
        const doc = new jsPDF();
    
        // Add logo
        const img = new Image();
        img.src = SidebarImage;
        doc.addImage(img, 'PNG', 10, 10, 50, 45);
    
        // Adjust the starting Y position for the text and tables to avoid overlap with the logo
        const startY = 60; // Adjust as needed to fit the logo size perfectly
    
        // Patient Overview
        doc.setTextColor(53, 73, 94); // Dark gray color
        doc.setFontSize(16);
        doc.setFont("helvetica", "bold"); // Set font to bold

        doc.text(`Patient Overview for ID: ${patientId}`, 70, 30);
    
        doc.autoTable({
            startY: startY,
            head: [['Phone Number', 'Full Doses', 'Partial Doses', 'Missed Doses', 'Adherence Rate', 'Severe ADRs']],
            body: [[
                patientOverview.phoneNumber,
                patientOverview.fullDoses,
                patientOverview.partialDoses,
                patientOverview.missedDoses,
                `${patientOverview.adherenceRate}%`,
                patientOverview.severeAdrs.join(', ')
            ]],
            headStyles: { fillColor: [149, 185, 219] }, // Change header row color
        });
    
        // Patient Logs
        doc.text(`Patient Logs `, 70, doc.autoTable.previous.finalY + 20);
        doc.autoTable({
            startY: doc.autoTable.previous.finalY + 30,
            head: [['Date', 'Adherence', 'ADRs']],
            body: patientLogs.map(log => [log.date, log.adherence, log.adrs]),
            headStyles: { fillColor: [149, 185, 219] }, // Change header row color
        });
    
        doc.save(`patient_data_${patientId}.pdf`);
    };

    const exportCSV = () => {
        const csvData = [
            {
                PhoneNumber: patientOverview.phoneNumber,
                FullDoses: patientOverview.fullDoses,
                PartialDoses: patientOverview.partialDoses,
                MissedDoses: patientOverview.missedDoses,
                AdherenceRate: `${patientOverview.adherenceRate}%`,
                SevereADRs: patientOverview.severeAdrs.join(', ')
            },
            ...patientLogs.map(log => ({
                Date: log.date,
                Adherence: log.adherence,
                ADRs: log.adrs
            }))
        ];
        const worksheet = XLSX.utils.json_to_sheet(csvData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Patient Data');
        const csvOutput = XLSX.write(workbook, { bookType: 'csv', type: 'array' });
        saveAs(new Blob([csvOutput], { type: 'text/csv' }), `patient_data_${patientId}.csv`);
    };

    const exportXLSX = () => {
        const xlsxData = [
            {
                PhoneNumber: patientOverview.phoneNumber,
                FullDoses: patientOverview.fullDoses,
                PartialDoses: patientOverview.partialDoses,
                MissedDoses: patientOverview.missedDoses,
                AdherenceRate: `${patientOverview.adherenceRate}%`,
                SevereADRs: patientOverview.severeAdrs.join(', ')
            },
            ...patientLogs.map(log => ({
                Date: log.date,
                Adherence: log.adherence,
                ADRs: log.adrs
            }))
        ];
        const worksheet = XLSX.utils.json_to_sheet(xlsxData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Patient Data');
        const xlsxOutput = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        saveAs(new Blob([xlsxOutput], { type: 'application/octet-stream' }), `patient_data_${patientId}.xlsx`);
    };

    if (loading) {
        return <p>Loading data, please wait...</p>;
    }

    return (
        <div className="export-data-container">
            <h2 className="export-title">Export Patient Data</h2>
            <div className="button-container">
                <button className="export-button" onClick={exportPDF}>Export as PDF</button>
                <button className="export-button" onClick={exportCSV}>Export as CSV</button>
                <button className="export-button" onClick={exportXLSX}>Export as XLSX</button>
            </div>
        </div>
    );
};

export default ExportData;
