import React, { useState } from "react";
import "../styles/addNewProvider.css";
import { useNavigate } from "react-router-dom";

// Placeholder for roles each role can invite
const rolePermissions = {
  "Super Admin": ["Program Admin", "Secondary Provider", "Primary Provider"],
  "Program Admin": ["Secondary Provider", "Primary Provider"],
  "Secondary Provider": ["Primary Provider"],
  "Primary Provider": ["Patient"],
};

const AddProviderPage = () => {
  const [members, setMembers] = useState({});
  const userRole = localStorage.getItem('userRole');
  const jwtToken = localStorage.getItem('jwtToken');
  const navigate = useNavigate();
  const [inviteRole, setInviteRole] = useState("");
  const [email, setEmail] = useState("");
  const [patientId, setPatientId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const canInvitePatients = userRole === 'Primary Provider';

  const handleInvite = async (e) => {
    e.preventDefault();
    

    const headers = {
      'Authorization': `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    };

    let payload = {};
    let endpoint = '';
    
    if (canInvitePatients) {
      const userId = localStorage.getItem('userId');

      try {
        // Fetch the list of all doctors to find the one matching the userId
        const allDoctorsResponse = await fetch('https://tracktb.com/doctors', {
          headers: headers,
        });

        if (!allDoctorsResponse.ok) {
          throw new Error(`HTTP error! status: ${allDoctorsResponse.status}`);
        }

        const allDoctorsData = await allDoctorsResponse.json();
        // Find the doctor with the matching userId
        const doctor = allDoctorsData.find(doc => doc.userId.toString() === userId);
 

        if (doctor) {

          // Prepare payload for inviting a patient with the matched doctorId
          payload = {
            patientId,
            phoneNumber,
            assignedProvider: doctor.doctorId.toString() // Ensure the doctorId is sent as a string if your backend expects a string
          };

          endpoint = 'https://tracktb.com/patients'; 
          
          const response = await fetch(endpoint, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(payload),
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const responseData = await response.json();
          
          // Navigate or update state to reflect the successful registration
          // navigate('/somewhere'); // Uncomment this if you want to navigate after successful registration

          // Reset form fields
          setPatientId("");
          setPhoneNumber("");
        } else {
          console.error("Doctor with matching userId not found");
        }
      } catch (error) {
        console.error('Error fetching doctor data:', error);
      }
    } else {
      // Handle invitation for other roles
      endpoint = `/api/providers/invite?email=${encodeURIComponent(email)}`;

      try {
        const response = await fetch(endpoint, {
          method: 'POST',
          headers: headers,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();
        
        // Reset form fields
        setEmail("");
        setInviteRole("");
      } catch (error) {
        console.error('Error inviting member:', error);
      }
    }
  };

  return (
    <div id="background">
      <div id="page-container">
        <h1 className="page-title">Invite New Member</h1>
        <form onSubmit={handleInvite} className="form-container">
          {/* Conditionally render the input fields based on the role */}
          {canInvitePatients ? (
            <>
              <input
                type="text"
                className="addNewProviderPageForm"
                placeholder="Patient ID"
                value={patientId}
                onChange={(e) => setPatientId(e.target.value)}
                required
              />
              <input
                type="tel"
                className="addNewProviderPageForm"
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
            </>
          ) : (
            <>
              <input
                type="email"
                className="addNewProviderPageForm"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <select
                value={inviteRole}
                className="addNewProviderPageForm"
                onChange={(e) => setInviteRole(e.target.value)}
                required
                style={{
                  padding: '10px',
                  marginBottom: '10px',
                  border: '1px solid #ddd',
                  borderRadius: '10px',
                  fontFamily: '"Roboto", sans-serif',
                }}
              >
                <option value="">Select Role</option>
                {rolePermissions[userRole]?.map((role) => (
                  <option key={role} value={role}>
                    {role}
                  </option>
                ))}
              </select>
            </>
          )}
          <button type="submit" className="form-button">
            Invite
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddProviderPage;