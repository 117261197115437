import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const fetchPatientDataByPatientId = async (patientId) => {
    const cachedResponses = localStorage.getItem(`responses_${patientId}`);
    if (cachedResponses) {
        return JSON.parse(cachedResponses); // Return the cached data
    }
  
    try {
        const response = await fetch(`https://tracktb.com/api/responses/patient/${patientId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });
  
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
  
        const data = await response.json();
        localStorage.setItem(`responses_${patientId}`, JSON.stringify(data));
        return data; // Return the actual API response data
    } catch (error) {
        console.error('Error fetching data:', error);
        return [];
    }
};

const SEVERITY_LABELS = {
    1: 'None',
    2: 'Mild',
    3: 'Moderate',
    4: 'Severe'
};

const ADR_OPTIONS = [
    { label: 'Weakness', questionID: 4 },
    { label: 'Rash, itching or jaundice', questionID: 5 },
    { label: 'Abdominal pains, nausea, vomiting or diarrhea', questionID: 6 },
    { label: 'Chest pains or palpitations', questionID: 7 },
    { label: 'Joint pains', questionID: 8 },
    { label: 'Difficulty urinating', questionID: 9 },
    { label: 'Depression, anxiety, seizures or numbness', questionID: 10 },
    { label: 'Blurred vision', questionID: 11 },
    { label: 'Hearing difficulties', questionID: 12 },
    { label: 'Pallor', questionID: 13 }
];

const PatientOverview = () => {
    const { patientId } = useParams();
    const userRole = localStorage.getItem('userRole');
    const [patientData, setPatientData] = useState({
        phoneNumber: '******',
        fullDoses: 0,
        partialDoses: 0,
        missedDoses: 0,
        adherenceRate: 0,
        severeAdrs: []
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadData = async () => {
            const data = await fetchPatientDataByPatientId(patientId);

            // Ensure responses is an array
            const responses = data.data || [];
            if (!Array.isArray(responses)) {
                console.error('Expected responses to be an array, but got:', responses);
                setLoading(false);
                return;
            }

            if (responses.length === 0) {
                setLoading(false);
                return;
            }

            const phoneNumber = responses[0]?.phoneNumber || '******';

            const question1Responses = responses.filter(r => r.questionID === 1);
            const totalQuestion1Responses = question1Responses.length;

            const full = question1Responses.filter(r => r.responseValue === 3).length;
            const partial = question1Responses.filter(r => r.responseValue === 2).length;
            const none = question1Responses.filter(r => r.responseValue === 1).length;
            const adherenceRate = totalQuestion1Responses ? (full / totalQuestion1Responses) * 100 : 0;

    
            const missedDoses = responses.filter(r => r.questionID === 1 && r.responseValue === 0).length;

            const severeAdrs = responses
                .filter(r => ADR_OPTIONS.some(option => option.questionID === r.questionID && r.responseValue === 4))
                .map(r => `${SEVERITY_LABELS[r.responseValue]} ${ADR_OPTIONS.find(option => option.questionID === r.questionID).label}`);


            setPatientData({
                phoneNumber: (userRole === 'Primary Provider' || userRole === 'Patient') ? phoneNumber : '******',
                fullDoses: full,
                partialDoses: partial,
                missedDoses: none,
                adherenceRate: adherenceRate.toFixed(2),
                severeAdrs: severeAdrs
            });

            setLoading(false);
        };

        loadData();
    }, [patientId, userRole]);

    if (loading) {
        return <p>Loading data, please wait...</p>;
    }

    return (
        <div className="patient-overview-container">
            <h2 className="overview-title">
                Patient {userRole === 'Primary Provider' ? <span className="bold-id">#{patientId}</span> : <span className="bold-id">******</span>} Overview
            </h2>
            <p className="overview-phone">
                Phone number: {patientData.phoneNumber}
            </p>
            <div className="overview-doses-container">
                <div className="doses-info">
                    <p className="full-doses">{patientData.fullDoses} Full doses taken</p>
                    <p className="partial-doses">{patientData.partialDoses} Partial doses taken</p>
                    <p className="missed-doses">{patientData.missedDoses} Dose missed</p>
                </div>
                <div className="adherence-progress-bar">
                    <CircularProgressbar value={patientData.adherenceRate} text={`${patientData.adherenceRate}%`} styles={buildStyles({
                        pathColor: '#82ca9d', textColor: '#82ca9d',
                    })}/>
                    <p>Adherence Rate</p>
                </div>
            </div>
            <h3 className="recent-adrs-title">Recent Severe ADRs:</h3>
            <ul className="adrs-list">
                {patientData.severeAdrs.length > 0 ? (
                    patientData.severeAdrs.map((reaction, index) => (
                        <li key={index}>{reaction}</li>
                    ))
                ) : (
                    <li>No severe ADRs reported</li>
                )}
            </ul>
        </div>
    );
};

export default PatientOverview;
