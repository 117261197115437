import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/LoginPage.css';
import logo from '../styles/images/SideBarImage.png';


/**
 * LoginPage Component
 * 
 * This component represents the login page of the application.
 * It allows users to input their email, password, and choose whether to remember their login credentials.
 * When the user submits the form, it sends a POST request to the backend for authentication.
 * If the authentication is successful, the user is redirected to the home page or dashboard.
 * If there's an authentication error, an error message is logged to the console.
 */

function LoginPage({ setIsAuthenticated }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [loginError, setLoginError] = useState('');
    const navigate = useNavigate();

    const handleCaretakerLogin = (event) => {

    navigate('/caretaker-login'); 
};


const handleLogin = async (e) => {
    e.preventDefault();
    try {
        const response = await fetch('https://tracktb.com/authenticate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username,
                password,
            }),
        });

        if (!response.ok) {
            if (response.status === 403) {
                setLoginError('Invalid credentials. Please try again.');
            } else {
                setLoginError(`An error occurred: ${response.statusText}`);
            }
            return;
        }

        const data = await response.json();
        localStorage.setItem('jwtToken', data.jwt);
        localStorage.setItem('userRole', data.userRole);
        localStorage.setItem('userId', data.userId);
        localStorage.removeItem('responses');

        // Save the current path
        localStorage.setItem('lastPath', '/dashboard'); // Adjust as necessary
        setIsAuthenticated(true);
        navigate('/dashboard'); // Adjust as necessary
    } catch (error) {
        console.error('Login error:', error);
        setLoginError('An error occurred. Please try again later.');
    }
};
  return (
      <div className="login-container">
          <form className="login-form" onSubmit={handleLogin}>
          <button type="button" id="caretakerButton" onClick={(e) => handleCaretakerLogin(e)}>Switch to Caretaker Sign In</button>
              <h1>Sign In</h1>
              <input
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
              />
              <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
              />
                  {loginError && <div className="error-message">{loginError}</div>}
              <button type="submit">Sign in</button>
            <a href="/forgot-password">Forgot Password?</a>
            <br/>

          </form>
      </div>
  );
}

export default LoginPage;