import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import DateRangeFilter from "../components/dateRangeFilter";
import AdherenceChart from "../components/adherenceChart";
import OverviewStats from "../components/overviewStats";
import { DataProvider } from '../DataContext'; // Import DataProvider

import ADRSeverityPieChart from "../components/adrSeverityPieChart";
import LogsTable from "../components/logsTable";
import LoadingScreen from "../components/loadingScreen";

const HomePage = () => {
  const [dateRange, setDateRange] = useState(() => {
    const savedDateRange = localStorage.getItem("dateRange");
    if (savedDateRange) {

      const parsedRange = JSON.parse(savedDateRange);
      return [new Date(parsedRange[0]), new Date(parsedRange[1])];
    } 

    return [new Date(new Date().setDate(new Date().getDate() - 30)), new Date()];
  });

  const [isLoading, setIsLoading] = useState(true);
  const userRole = localStorage.getItem("userRole");

  useEffect(() => {
    localStorage.setItem("dateRange", JSON.stringify(dateRange));
  }, [dateRange]);

  const LoadingSpinner = () => {
    return (
      <div className="loading-spinner">
        <div className="spinner"></div>
        <p>Loading...</p>
      </div>
    );
  };

  return (
    <DataProvider>
    <div className="container">
      {isLoading && <LoadingSpinner />}
      <Box className="grid-container">
        <DateRangeFilter dateRange={dateRange} setDateRange={setDateRange} />
        <AdherenceChart dateRange={dateRange} />
        <div className="inline-components-container">
        <OverviewStats />
          <ADRSeverityPieChart />
          {(userRole === 'Primary Provider' || userRole === 'Secondary Provider') && (
            <LogsTable setIsLoading={setIsLoading} />
          )}
        </div>
      </Box>
    </div>
    </DataProvider>

  );
};

export default HomePage;