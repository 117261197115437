import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import "../styles/homePage.css";

const CACHE_DURATION = 60 * 60 * 1000; // 1 hour in milliseconds
const CACHE_KEY = 'patientLogs';

const fetchPatientLogs = async () => {
    try {
        const response = await fetch('https://tracktb.com/api/responses/patient-logs', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log('Fetched patient logs:', data);
        localStorage.setItem(CACHE_KEY, JSON.stringify({ data, timestamp: new Date().getTime() }));
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return [];
    }
};

const getPatientLogs = async () => {
    const cachedData = localStorage.getItem(CACHE_KEY);

    if (cachedData) {
        const parsedData = JSON.parse(cachedData);
        const cacheAge = new Date().getTime() - parsedData.timestamp;

        if (cacheAge < CACHE_DURATION) {
            console.log('Using cached patient logs:', parsedData.data);
            return parsedData.data;
        } else {
            console.log('Cache expired, fetching new data');
            localStorage.removeItem(CACHE_KEY);
        }
    } else {
        console.log('No cache found, fetching new data');
    }
    return await fetchPatientLogs();
};

const LogsTable = ({ setIsLoading }) => {
    const [logs, setLogs] = useState([]);
    const [patients, setPatients] = useState([]);
    const userRole = localStorage.getItem('userRole');
    const navigate = useNavigate();

    const fetchDoctorAndPatients = async () => {
        const userId = localStorage.getItem('userId');
        const userRole = localStorage.getItem('userRole');

        try {
            const doctorResponse = await fetch(`https://tracktb.com/doctors/user/${userId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
                },
            });

            if (!doctorResponse.ok) {
                throw new Error('Failed to fetch doctor information');
            }

            const doctorData = await doctorResponse.json();
            const doctorId = doctorData.doctorId;
            const patientsResponse = await fetch('https://tracktb.com/patients', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
                },
            });

            if (!patientsResponse.ok) {
                throw new Error('Failed to fetch patients');
            }

            let patientsData = await patientsResponse.json();
            if (userRole === 'Primary Provider' && doctorId) {
                const doctorIdStr = doctorId.toString();
                patientsData = patientsData.filter(patient =>
                    patient.assignedProvider === doctorIdStr
                );
            }

            setPatients(patientsData);

            const logs = await getPatientLogs();
            console.log('Fetched logs:', logs);
            const filteredLogs = logs.filter(log => {
                const matchingPatient = patientsData.find(patient => patient.patientId?.toString() === log.patientID);
                return matchingPatient;
            });

            console.log('Filtered logs:', filteredLogs);
            setLogs(filteredLogs);
        } catch (error) {
            console.error('Error loading data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        fetchDoctorAndPatients();
        fetchPatientLogs();

        window.addEventListener('clearCache', fetchDoctorAndPatients);

        return () => {
            window.removeEventListener('clearCache', fetchDoctorAndPatients);
        };
    }, []);

    const handleRowClick = (patientID) => {
        navigate(`/patient/${patientID}`);
    };

    return (
        <div className="logs-table-container">
            <div style={{ height: '400px', overflowY: 'scroll' }}>
                <table>
                    <thead>
                        <tr>
                            <th>Patient ID</th>
                            <th>Reason</th>
                            <th>Details</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {logs.map((log) => (
                            <tr key={log.patientID} onClick={() => handleRowClick(log.patientID)}>
                                <td>{userRole === 'Primary Provider' ? log.patientID : '******'}</td>
                                <td>{log.reason}</td>
                                <td>{log.details}</td>
                                {(userRole === 'Primary Provider' || userRole === 'Secondary Provider') && <td>
                                    <ArrowForwardIcon className="arrowIcon" />
                                </td>}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default LogsTable;
