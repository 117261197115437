import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import "../styles/homePage.css";

const COLORS = ['#ff6361', '#8884d8', '#ffc658', '#00C49F'];
const CACHE_DURATION = 60 * 60 * 1000; // 1 hour in milliseconds

const ADRSeverityPieChart = ({ setIsAdrSeverityPieChartLoading }) => {
  const [selectedADR, setSelectedADR] = useState('Weakness');
  const [chartData, setChartData] = useState([]);
  const [totalResponses, setTotalResponses] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const ADR_OPTIONS = [
    { label: 'Weakness', questionID: 4 },
    { label: 'Rash, itching or jaundice', questionID: 5 },
    { label: 'Abdominal pains, nausea, vomiting or diarrhea', questionID: 6 },
    { label: 'Chest pains or palpitations', questionID: 7 },
    { label: 'Joint pains', questionID: 8 },
    { label: 'Difficulty urinating', questionID: 9 },
    { label: 'Depression, anxiety, seizures or numbness', questionID: 10 },
    { label: 'Blurred vision', questionID: 11 },
    { label: 'Hearing difficulties', questionID: 12 },
    { label: 'Pallor', questionID: 13 }
  ];

  const Loading = () => {
    return (
      <div className="loading-overlay">
        <div className="spinner"></div>
      </div>
    );
  };
  const fetchData = async (questionID) => {
    const cacheKey = `adr_severity_${questionID}`;
    const cachedData = localStorage.getItem(cacheKey);

    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      if (Date.now() - timestamp < CACHE_DURATION) {
        console.log('Using cached data for questionID:', questionID);
        return data;
      }
    }

    const endpoint = `https://tracktb.com/api/responses/adr-severity?questionID=${questionID}`;
    console.log(`Fetching data from endpoint: ${endpoint}`);

    try {
      const token = localStorage.getItem('jwtToken');
      if (!token) {
        throw new Error('JWT token not found in localStorage');
      }

      const response = await fetch(endpoint, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Fetched data:', data);

      localStorage.setItem(cacheKey, JSON.stringify({ data, timestamp: Date.now() }));
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.message);
      return null;
    }
  };

  const handleClearCache = async () => {
    setLoading(true); // Set loading to true when starting to fetch data
    const selectedQuestionID = ADR_OPTIONS.find(option => option.label === selectedADR).questionID;

    localStorage.removeItem(`adr_severity_${selectedQuestionID}`);
    
    const data = await fetchData(selectedQuestionID);
    if (data) {
      const processedData = [
        { name: 'Severe', value: data[0]?.value ? parseFloat(data[0].value.toFixed(1)) : 0 },
        { name: 'Moderate', value: data[1]?.value ? parseFloat(data[1].value.toFixed(1)) : 0 },
        { name: 'Mild', value: data[2]?.value ? parseFloat(data[2].value.toFixed(1)) : 0 },
        { name: 'None', value: data[3]?.value ? parseFloat(data[3].value.toFixed(1)) : 0 }
      ];

      console.log('Processed data before setting state:', processedData);

      const total = data[0]?.value + data[1]?.value + data[2]?.value + data[3]?.value;
      setTotalResponses(total ? Math.round(total) : 0);
      setChartData(processedData);
    }
    setLoading(false); // Set loading to false when data is loaded
  };

  useEffect(() => {
    const selectedQuestionID = ADR_OPTIONS.find(option => option.label === selectedADR).questionID;
    fetchData(selectedQuestionID).then(data => {
      if (data) {
        const processedData = [
          { name: 'Severe', value: data[0]?.value ? parseFloat(data[0].value.toFixed(1)) : 0 },
          { name: 'Moderate', value: data[1]?.value ? parseFloat(data[1].value.toFixed(1)) : 0 },
          { name: 'Mild', value: data[2]?.value ? parseFloat(data[2].value.toFixed(1)) : 0 },
          { name: 'None', value: data[3]?.value ? parseFloat(data[3].value.toFixed(1)) : 0 }
        ];

        console.log('Processed data before setting state:', processedData);

        const total = data[0]?.value + data[1]?.value + data[2]?.value + data[3]?.value;
        setTotalResponses(total ? Math.round(total) : 0);
        setChartData(processedData);
      }
      setLoading(false); // Set loading to false when data is loaded
    });

    window.addEventListener('clearCache', handleClearCache);

    return () => {
      window.removeEventListener('clearCache', handleClearCache);
    };
  }, [selectedADR]);

  useEffect(() => {
    console.log('chartData updated:', chartData);
  }, [chartData]);

  console.log('Rendering PieChart with chartData:', chartData);

  return (
    <div className="adr-pie-container" style={{ position: 'relative' }}>
      <h2 className="adr-title">ADR Severity</h2>
      <select
        className="adr-select"
        value={selectedADR}
        onChange={(e) => {
          setSelectedADR(e.target.value);
          setLoading(true); // Set loading to true when ADR changes
        }}
      >
        {ADR_OPTIONS.map(option => (
          <option key={option.questionID} value={option.label}>{option.label}</option>
        ))}
      </select>
      {loading ? (
        <Loading /> // Display loading component when data is being fetched
      ) : error ? (
        <p>Error: {error}</p>
      ) : chartData && chartData.length > 0 ? (
        <div className="pie-chart-container">
          <PieChart width={250} height={200}>
            <Pie
              data={chartData}
              cx={125}
              cy={100}
              labelLine={false}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              {chartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip formatter={(value) => `${value.toFixed(1)}%`} />
          </PieChart>
          <p>Total responses: {totalResponses}</p>
        </div>
      ) : (
        <p>No data available</p>
      )}
    </div>

  );
};

export default ADRSeverityPieChart;